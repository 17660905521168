@media screen {

  g.highcharts-button.button-normal rect.button-box { margin-left: 5px; }
  g.highcharts-button.button-pressed rect.button-box { margin-left: 5px; }

  .highcharts-point.zone-1 { fill: #52be80; }
  .highcharts-point.zone-2 { fill: #5dade2; }
  .highcharts-point.zone-3 { fill: #ffd700; }
  .highcharts-point.zone-4 { fill: #ffaa1c; }
  .highcharts-point.zone-5 { fill: #ff0000; }
  .highcharts-point.zone-6 { fill: #8e44ad; }

  span.early,
  span.high_fzn { color: #8e44ad; font-weight: bold; }
  span.high { color: #ff0000; font-weight: bold; }
  span.late { color: #aaaaaa; font-weight: bold; }
  span.favorable,
  span.ideal,
  span.no_risk { color: #52be80; font-weight: bold; }
  span.no_risk_fzn { color: #5dade2; font-weight: bold; }
  span.marginal,
  span.low { color: #ffd700; font-weight: bold; }
  span.moderate { color: #ffaa1c; font-weight: bold; }
  
  #turf-unsupported-content {
    border: 5px solid #dd0000;
    background-color: #eeeeee;
    color: #dd0000;
    /* display: table-cell; */
    font-size: 28px;
    font-weight: bold;
    height: 250px;
    margin: 25px;
    max-width: 760px;
    width: 90%;
  }
  .turf-content-warning {
    position: relative;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  /* dashboard containers */
  #turf-dashboard {
    width: 100%;
    max-width: 800px;
    height: auto; margin: auto;
    -webkit-transition: width all 1s linear;
    -moz-transition: width 1s linear;
    transition: width 1s linear;
  }

  #turf-dashboard-summary {
    width: 100%;
    text-align: center;
    vertical-align: middle;
    font-size: 24px;
    font-weight: normal;
    /* margin-top: 0.5em; */
    /* margin-bottom: 0.5em; */
    margin-top: 1.5em;
    //margin-bottom: 1.5em;
    padding-top: 0;
    padding-bottom: 0;
  }

  #turf-dashboard-summary .summary-text { font-weight: bold; padding-top: 12px; }
  #turf-dashboard-summary .summary-risk-text { padding-left: 5px; font-weight: bold; color: #8e44ad; }
  #turf-dashboard-summary .no-risk { padding-left: 5px; font-size: 36px; font-weight: bold; color: #52be80; }
  #turf-dashboard-summary .low-risk { padding-left: 5px; font-size: 36px; font-weight: bold; color: #ffd700; }
  #turf-dashboard-summary .moderate-risk { padding-left: 5px; font-size: 36px; font-weight: bold; color: #ffaa1c; }
  #turf-dashboard-summary .high-risk { padding-left: 5px; font-size: 36px; font-weight: bold; color: #ff0000; }
  #turf-dashboard-summary .no-risk-fzn { padding-left: 5px; font-size: 36px; font-weight: bold; color: #5dade2; }
  #turf-dashboard-summary .high-risk-fzn { padding-left: 5px; font-size: 36px; font-weight: bold; color: #8e44ad; }
  #turf-dashboard-summary .summary-fcst-time-note { padding-left: 5px; vertical-align: top; font-size: 14px; font-weight: normal; color: #666666; }

 .highcharts-point.zone-1 { fill: #52be80; }
  .highcharts-point.zone-2 { fill: #5dade2; }
  .highcharts-point.zone-3 { fill: #ffd700; }
  .highcharts-point.zone-4 { fill: #ffaa1c; }
  .highcharts-point.zone-5 { fill: #ff0000; }
  .highcharts-point.zone-6 { fill: #8e44ad; }

  #turf-dashboard-location {
    width: 100%;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
    font-weight: normal;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding-top: 0;
    padding-bottom: 0;
  }
  #turf-dashboard-location .location-title { font-size: 24px; font-weight: bold; padding-top: 12px; }
  #turf-dashboard-location .location-address { padding-top: 12px; }
  #turf-dashboard-location .location-coordinates { padding-left: 5px; color: #45930b; }
  #turf-dashboard-location .location-note { padding-left: 5px; vertical-align: top; font-size: 14px; font-weight: normal; color: #666666; }

  #turf-dashboard-location .location-assumptions-text { margin-top: 0.0em; margin-bottom: 0.0em; padding-left: 5px; vertical-align: top; font-size: 16px; font-weight: normal; color: #000000; }
  #turf-dashboard-location .location-assumptions-items { margin-top: 0.0em; margin-bottom: 0.0em; padding-left: 5px; vertical-align: top; font-size: 18px; font-weight: bold; color: #000000; }
  #turf-dashboard-location .location-assumptions-warning { margin-top: 0.0em; margin-bottom: 0.0em; padding-left: 5px; vertical-align: top; font-size: 16px; font-weight: bold; color: #ff0000; }

  .dashboard-change-location {
     background-color: #33ffff;
     border: 2px solid #0000ff;
     border-radius: 5px;
     box-shadow: none;
     color: #000000;
     font-size: 16px;
     height: 24px;
     line-height: 18px;
     margin-bottom: 0;
     margin-left: 10px;
     padding: 2px 5px;
     transition: background-color 0.24s ease-in-out;
     vertical-align: middle;
     width: auto;
  }
  .dashboard-change-location:hover {
     background-color: #00ff00;
     color: #006600;
  }

  .dashboard-title {
     width: 100%;
     padding-top: 5px;
     padding-bottom: 5px;
     /*font-size: 20px;*/
     font-size: 22px;
     font-weight: bold;
     text-align: center;
     vertical-align: middle;
  }

  #turf-dashboard-elements { 
     width: 95%; 
     max-width:1080px;
     height: auto;
     margin-left: 5%;
     margin-bottom:0.5em; 
     font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
     text-align: center;
     vertical-align: middle;
  } 
  #turf-dashboard,
  #turf-dashboard-location,
  #turf-dashboard-footer { padding-right: 5px; }

  .nre-note { font-size: 12px; color: #666666; vertical-align: top; }
  
  .turf-dashboard-table {
     width: 100%;
     height: auto;
     margin: 0;
     padding-left: 2px;
  }

  .turf-dashboard-element {
     width: 100%;
     height: auto;
     margin-left: auto; 
     margin-bottom: 5px;
     background-color: #ffffff;
     /* border: 2px solid #000000; */
  }

  .dashboard-description {
     display: inline;
     width: 80%;
     font-size: 16px;
     color: #000000;
     text-align: justify;
     margin-left: 10%;
  }
  .dashboard-description h3 { 
      font-size: 16px;
      text-align: left;
  }

  .dashboard-element-header {
     width: 100%;
     margin-top: 5px;
     margin-bottom: 5px;
  }

  .dashboard-element-legend {
     display: inline-block;
     font-size: 16px;
     font-weight: normal; 
     margin-left: 5px;
  }
  .legend-circle { 
     display: inline-block;
     width: 14px;
     height: 14px;
     border-radius: 7px;
     margin-left: 5px;
     margin-right: 5px;
  }
  .legend-circle.late { background-color: #aaaaaa; }
  .legend-circle.favorable,
  .legend-circle.ideal,
  .legend-circle.no_risk { background-color: #52be80; }
  .legend-circle.no_risk_fzn { background-color: #5dade2; }
  .legend-circle.early,
  .legend-circle.high { background-color: #ff0000; }
  .legend-circle.high_fzn { background-color: #8e44ad; }
  .legend-circle.marginal,
  .legend-circle.moderate { background-color: #ffaa1c; }
  .legend-circle.low { background-color: #ffd700; }

  #dashboard-chart-container {
    width: 99%;
    max-width: 680px;
    height: auto;
    margin: auto;
    margin-bottom: 5px;
    background-color: #ffffff;
  }

  .turf-dashboard-thumbnails {
    display: flex;
    flex-direction: row;
    flex: 0 1 length;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  div.thumb {
    width: 12%;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  img.thumbnail {
    width: 100%;
    height: auto;
    border: 1px solid #000000;
    background-color: #eeeeee;
  }
  span.thumbnail-date {
    width: 14%;
    height: auto;
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    border: none;
  }

/* series */
/* data series */
  #daily-data,
  #average-data,
  #dashboard-dates { height: 33%; }

  th.series-name {
    width: 14%;
    text-align: right;
    vertical-align: middle;
    font-size: 15px;
    font-weight: normal;
    padding-right: 5px;
    border: 1px solid #000000;
    border-right: none;
    -webkit-transition: width 1s linear;
    -moz-transition: width 1s linear;
    transition: width 1s linear;
  }
  th.series-name.data { border-bottom: none; }
  td.series-data { width: 100%; max-width: 650px; }
  td span.data {
    float: left;
    width: 9.5%;
    background-color: #ffffff;
    margin: auto;
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
  }
  td span.data { background-color: #ffffff; }
  td span.data.late { background-color: #aaaaaa; }
  td span.data.favorable,
  td span.data.ideal,
  td span.data.no_risk { background-color: #52be80; }
  td span.data.no_risk_fzn { background-color: #5dade2; }
  td span.data.early,
  td span.data.high { background-color: #ff0000; }
  td span.data.high_fzn { background-color: #8e44ad; }
  td span.data.marginal,
  td span.data.low { background-color: #ffd700; }
  td span.data.moderate { background-color: #ffaa1c; }
  /* CSS sibling combinator to get blue left border on first fcast day */
  td > span.data.fcast { border-left: 4px solid #0000ff; }
  td > span.data.fcast ~ span.data.fcast { border-left: 1px solid #000000; }
  
/* date series */
  td span.date {
    float: left;
    width: 9.5%;
    background-color: #ffffff;
    margin: auto;
    border: 1px solid #000000;
    border-right: none;
    text-align: center;
    vertical-align: middle;
    -webkit-transition: width 1s linear;
    -moz-transition: width 1s linear;
    transition: width 1s linear;
  }
  td span.date.obs { background-color: #ccffcc; }
  td span.date.fcast { background-color: #cceeff; }
  /* CSS sibling combinator to get blue left border on first fcast day */
  td > span.date.fcast { border-left: 4px solid #0000ff; }
  td > span.date.fcast ~ span.date.fcast { border-left: 1px solid #000000; }
  /* CSS last child to draw the right border on last span */
  td span:last-child { border-right: 1px solid #000000; }
}

/* sizing */
@media only screen and (max-width: 800px) { 
  th.series-name {  font-size: 15px; }
}
@media only screen and (max-width: 780px) {
  th.series-name { font-size: 14px; }
  td span.date { font-size: 13px; }
  .dashboard-description { font-size: 14px; }
}
@media only screen and (max-width: 722px) {
  .dashboard-description { display: inline; }
}
@media only screen and (max-width: 720px) {
  .dashboard-description { display: none; }
}
@media only screen and (max-width: 710px) {
  button.threat-location { width: 26em; font-size: 16px; }
  button.disease-map { width: 8.5em; font-size: 16px; }
  th.series-name { font-size: 13px; }
     display: inline;
}
@media only screen and (max-width: 670px) {
  #turf-page-title { font-size: 26px; }
  #turf-dashboard-location { font-size: 22px; }
  .turf-dashboard-element { margin-bottom: 3px; }

  .dashboard-element-name {
    font-size: 16px;
    padding-bottom: 3px;
    padding-top: 3px;
  }
  .dashboard-element-legend { font-size: 12px; }
  .legend-circle {
    width: 11px;
    height: 11px;
    border-radius: 6px;
    margin-left: 6px;
    margin-right: 6px;
  }
  th.series-name { font-size: 12px; }
  td span.date { font-size: 12px; }
}
@media only screen and (max-width: 650px) {
}
@media only screen and (max-width: 620px) {
  #turf-dashboard { padding-left: 5px; }
  button.threat-location { width: 24em; font-size: 14px; }
  button.disease-map { width: 8em; font-size: 14px; }
  th.series-name { font-size: 11px; }
  span.thumbnail-date { font-size: 12px; }
  .dashboard-description { font-size: 12px; }
}

@media only screen and (max-width: 580px) {
  th.series-name { font-size: 10px; }
}
@media only screen and (max-width: 560px) {
  #turf-page-title { font-size: 24px; }
  #turf-dashboard-location { font-size: 18px; }
  .dashboard-element { margin-bottom: 2px; }
  .dashboard-element-name {
    font-size: 14px;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .dashboard-element-legend { font-size: 10px; }
  .legend-circle {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }
  th.series-name { font-size: 9px; }
  td span.date { font-size: 10px; }
  span.thumbnail-date { font-size: 10px; }
}
@media only screen and (max-width: 520px) {
  button.threat-location { width: 22em; font-size: 12px; }
  button.disease-map { width: 8em; font-size: 12px; }
  th.series-name { font-size: 8px; }
}
@media only screen and (max-width: 480px) {
  #turf-page-title { font-size: 18px; }
  #turf-dashboard-location { font-size: 15px; }
  th.series-name { font-size: 8px; }
}
@media only screen and (max-width: 450px) {
  th.series-name { font-size: 7px; }
}
@media only screen and (max-width: 400px) {
  th.series-name { font-size: 6px; }
  td span.date { font-size: 9px; }
}
